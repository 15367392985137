import React from 'react';
import {
    Router,
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom';
import PUBLIC_ROUTE from './route/route.constants';
import Home from './components/home';
import { strings } from './string';
import * as anchor from "@project-serum/anchor";

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
    ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
    : undefined;

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.GIANT,
        data: {
            title: 'Giant',
            imgUrl: 'https://files.opengalaxy.com/starIcons/Giant_icon.png',
            description: strings.descriptions.giant,
            candyMachineId : new anchor.web3.PublicKey("ESE1jKEx61huQHomxo3cYoBU3v7VtRknPrcWCbHyhMjB"),
        },
        component: Home,
    },
    {
        path: PUBLIC_ROUTE.BRIGHT_GIANT,
        data: {
            title: 'Bright Giant',
            imgUrl: 'https://files.opengalaxy.com/starIcons/BrightGiant_icon.png',
            description: strings.descriptions.brightGiant,
            candyMachineId : new anchor.web3.PublicKey("ExMhEQxr8H9vBnBgSftZjBaV5ohWdpam5f8j8njvNUuT"),
        },
        component: Home,
    },
    {
        path: PUBLIC_ROUTE.SUPER_GIANT,
        data: {
            title: 'Super Giant',
            imgUrl: 'https://files.opengalaxy.com/starIcons/SuperGiant_icon.png',
            description: strings.descriptions.superGiant,
            candyMachineId : new anchor.web3.PublicKey("238aY1CEo8LsUbB6tmMcuCfF9eM6SkP46zwg2yrSMHBv"),
        },
        component: Home,
    },
    {
        path: PUBLIC_ROUTE.HYPER_GIANT,
        data: {
            title: 'Hyper Giant',
            imgUrl: 'https://files.opengalaxy.com/starIcons/HyperGiant_icon.png',
            description: strings.descriptions.hyperGiant,
            candyMachineId : new anchor.web3.PublicKey("H9DgRYwn1QoX9bLqAzDXwHcjGwSNwHs4BJtcX1FEFa3g"),
        },
        component: Home,
    },
];

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}>
                </Route>
                {
                    publicRoutes.map((route) => {
                        return (
                            <Route key={route.path} path={route.path} element={<route.component data={route.data} />} />
                        );
                    })
                }
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;

