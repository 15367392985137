import React from 'react';
import ReactPlayer from 'react-player';

import "../App.css";
import { useMemo } from "react";

import Minter from "../Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
    getPhantomWallet,
    getSolflareWallet,
    getSolletWallet,
    getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
    ConnectionProvider,
    WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { Link } from 'react-router-dom';

const theme = createTheme({
    palette: {
        type: "dark",
    },
});

 const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
     ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
     : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

interface DataType {
    title?: string
    imgUrl?: string
    description?: string
    candyMachineId?: anchor.web3.PublicKey,
}

interface HomeProps {
    data?: DataType
}

const Home = (props: HomeProps) => {
    const { data } = props;
    console.log("data of home", data);


    const endpoint = useMemo(() => clusterApiUrl(network), []);

    const wallets = useMemo(
        () => [
            getPhantomWallet(),
            getSolflareWallet(),
            getSolletWallet(),
            getMathWallet(),
        ],
        []
    );

    function toggleMenu() {
        const menu = document.getElementById("mobileNavContainer")!;
        menu.classList.toggle("open-menu");
        console.log("pressed");
    }

    return (

        <div>
            <div className="home-banner">
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    id="video"
                    poster="https://files.opengalaxy.com/videos/videos/sunLight.jpg"
                    className="home-video"
                >
                    <source
                        src="https://files.opengalaxy.com/videos/sunLight.mp4"
                        type="video/mp4"
                    />
                    <source
                        src="https://files.opengalaxy.com/videos/sunLight.ogv"
                        type="video/ogv"
                    />
                    <source
                        src="https://files.opengalaxy.com/videos/sunLight.webm"
                        type="video/webm"
                    />
                </video>
            </div>


            <div className="mainContent">
                <header className="viewport-header">
                    <div className="header-containerr">

                        <div className="logo block">
                            <a href="https://www.opengalaxy.com" target="_blank">
                                <img
                                    className="logo-icon"
                                    src="https://files.opengalaxy.com/images/openGalaxyLogo.png"
                                    alt="openGalaxy"
                                />
                            </a>
                        </div>

                        <div className="star-type-list">
                            <Link to='/giant'>
                                <div className="star-type">
                                    <img src="https://files.opengalaxy.com/starIcons/Giant_icon.png" alt="Giant_icon" />
                                </div>
                            </Link>
                            <Link to='/bright-giant'>
                                <div className="star-type">
                                    <img src="https://files.opengalaxy.com/starIcons/BrightGiant_icon.png" alt="BrightGiant_icon" />
                                </div>
                            </Link>
                            <Link to='/super-giant'>
                                <div className="star-type">
                                    <img src="https://files.opengalaxy.com/starIcons/SuperGiant_icon.png" alt="SuperGiant_icon" />
                                </div>
                            </Link>
                            <Link to='/hyper-giant'>
                                <div className="star-type">
                                    <img src="https://files.opengalaxy.com/starIcons/HyperGiant_icon.png" alt="SubGiant_icon" />
                                </div>
                            </Link>
                        </div>

                    </div>
                </header>

                <div className="title max-width-800">
                    <h1>Claim your place in space!</h1>
                    { (!data) && (
                    <div>

                    <p className="text-center">Every star system is a living being with thoughts, feelings and friends.</p>
                    <div className="star-type-list-home">
                            <Link to='/giant'>
                                <div className="star-type">
                                    <img src="https://files.opengalaxy.com/starIcons/Giant_icon.png" alt="Giant_icon" />
                                </div>
                            </Link>
                            <Link to='/bright-giant'>
                                <div className="star-type">
                                    <img src="https://files.opengalaxy.com/starIcons/BrightGiant_icon.png" alt="BrightGiant_icon" />
                                </div>
                            </Link>
                            <Link to='/super-giant'>
                                <div className="star-type">
                                    <img src="https://files.opengalaxy.com/starIcons/SuperGiant_icon.png" alt="SuperGiant_icon" />
                                </div>
                            </Link>
                            <Link to='/hyper-giant'>
                                <div className="star-type">
                                    <img src="https://files.opengalaxy.com/starIcons/HyperGiant_icon.png" alt="SubGiant_icon" />
                                </div>
                            </Link>
                        </div>
                    <div className="container">
                    <p className="text-center">Stars can host planets and are distinguished by their rarity</p>
                    <p className="text-center">and location in the openGalaxy as well as by their</p>
                    <p className="text-center">unique physical characteristics and capabilities.</p>
                    </div>
                    </div>
                    )}
                </div>

                <div className="star max-width-600">
                    <h3 className="text-center text-lg">{data?.title || 'Hyper Giant'}</h3>
                    <img
                        src={data?.imgUrl || "https://files.opengalaxy.com/starIcons/HyperGiant_icon.png"}
                        alt={`${data?.title || 'Hyper Giant'} icon`}
                    />
                    <p className="text-center">{data?.description || 'Shining almost impossibly bright and emitting mighty stellar winds, Hyper Giants possess that rare type of consciousness that can only be described as channeling pure genius.  Each Hyper Giant is utterly unique, and they lord over their space with swagger and flair.'}</p>
                </div>

                <div>
                    <ThemeProvider theme={theme}>
                        <ConnectionProvider endpoint={endpoint}>
                            <WalletProvider wallets={wallets} autoConnect>
                                <WalletDialogProvider>
                                    <Minter
                                        candyMachineId={data?.candyMachineId || candyMachineId}
                                        connection={connection}
                                        startDate={startDateSeed}
                                        txTimeout={txTimeout}
                                        rpcHost={rpcHost}
                                    />
                                </WalletDialogProvider>
                            </WalletProvider>
                        </ConnectionProvider>
                    </ThemeProvider>
                </div>

                <div className="empty-container"></div>
            </div>

            

            <div className="bottom-right">

                <img src="/img/ukraine.png"
                        alt="stand by Ukraine!"
                    />
            </div>
        </div>

    );
};

export default Home;
